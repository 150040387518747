import React from "react"

import websiteIcon from "../images/icon-website.png";
import linkedInIcon from "../images/icon-linkedin.png";
import youtubeIcon from "../images/icon-youtube.png";

const SocialButtons = () => (
  <div id="social-buttons" className="bg-white shadow">
    <div>
      <a target="_blank" href="http://www.cleeven.com" className="btn btn-light">
        <img src={websiteIcon} alt="Site Web"/>
      </a>
    </div>
    <div>
      <a target="_blank" href="https://www.linkedin.com/company/cleeven/" className="btn btn-light">
        <img src={linkedInIcon} alt="LinkedIn"/>
      </a>
    </div>
    <div>
      <a target="_blank" href="https://www.youtube.com/channel/UCJsPLCsRBLxSaY561Tlhrlg/featured" className="btn btn-light">
        <img src={youtubeIcon} alt="Youtube"/>
      </a>
    </div>
  </div>
);

export default SocialButtons;