import React, { Children } from "react"
import SectionHeader from "./sectionHeader"
import YouTube from "react-youtube"

import iconPlay from "../images/icon-play.png";

class Section extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoTarget: null,
      hidden: true
    };
  }

  _onReady(event) {
    this.state.videoTarget = event.target;
  }

  _playVideo() {
    if (this.state.videoTarget) {
      this.setState({
        hidden: false
      })
      this.state.videoTarget.playVideo();
    }
  }

  render() {
    const { children, videoId, imageUrl, contain, minHeight, inversed, whiteText, title, bgColor } = this.props;

    let image;
    let videoEl;

    if (videoId) {
      videoEl = <YouTube videoId={videoId} opts={{
        height: '100%',
        width: '100%',
        playerVars: {
          autoplay: 0
        }
      }}
     onReady={this._onReady.bind(this)}
     containerClassName={`${this.state.hidden ? 'hidden': ''} h-100`}/>;
    }

    if (imageUrl) {
      image = <div style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: contain ? 'contain' : 'cover',
        minHeight: minHeight ? minHeight : 500,
      }} onClick={this._playVideo.bind(this)} className={`col-md-12 img ${!inversed ? 'pr-0 rounded-right' : 'pl-0 rounded-left'} overflow-hidden align-items-center justify-content-center d-flex ${this.state.hidden ? '' : 'hidden'}`}>
        {videoId && <img src={iconPlay} alt="Play Video"/>}
      </div>;
    }

    const content = <div className="col-md-6 pt-4">
      <SectionHeader inversed={inversed} title={title} whiteText={whiteText}/>
      <div className={`col-md-12 ${whiteText ? 'text-white' : ''}`}>
        {children}
      </div>
    </div>

    let structure;

    if (inversed) {
      structure = <>
        {content}
        <div className="col-md-6">
          {image && image}
          {videoEl && videoEl}
        </div>
      </>
    } else {
      structure = <>
        <div className="col-md-6">
          {image && image}
          {videoEl && videoEl}
        </div>
        {content}
      </>
    }

    return (<
      div className="section pt-5 pb-5 col-md-12" style={{ backgroundColor: bgColor }}>
      <div className="row">
        {structure}
      </div>
    </div>)
  }
}

export default Section