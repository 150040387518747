import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import CleevenLogoImg from "../images/cleeven-logo.png"
import homeIcon from "../images/icon-home.png"
import hamburgerIcon from "../images/icon-hamburger.png"

const Header = () => (
  <nav className="navbar-expand-lg sticky-top bg-white">
    <Link id="header" to="/">
      <img src={CleevenLogoImg} alt="Cleeven Logo" />
    </Link>
    <button
      style={{ borderRadius: 0 }}
      className="navbar-toggler col-md-12 mt-2 bg-blue"
      type="button"
      data-toggle="collapse"
      data-target="#navbarMenu"
      aria-controls="navbarMenu"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon">
        <img src={hamburgerIcon} alt="navigation" />
      </span>
    </button>
    <nav
      id="navbarMenu"
      className="navigation collapse navbar-collapse justify-content-center"
    >
      <ul className="nav nav-pills">
        <li className="nav-item">
          <Link to="/">
            <div className="nav-link">
              <img src={homeIcon} alt="Accueil" />
            </div>
          </Link>
        </li>
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            data-toggle="dropdown"
            href="#"
            role="button"
          >
            Cleeven s'engage
          </a>
          <div className="dropdown-menu">
            <Link
              to="/engagement"
              className="dropdown-item text-uppercase text-grey"
            >
              Politique Qualité
            </Link>
            <Link
              to="/engagement"
              className="dropdown-item text-uppercase text-grey"
            >
              Engagements RSE
            </Link>
          </div>
        </li>
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            data-toggle="dropdown"
            href="#"
            role="button"
          >
            Présentation du groupe Cleeven
          </a>
          <div className="dropdown-menu">
            <Link to="/groupe">
              <div className="dropdown-item text-uppercase text-grey">
                Culture et Valeurs
              </div>
            </Link>
            <Link to="/groupe">
              <div className="dropdown-item text-uppercase text-grey">
                Sociétés et organigramme
              </div>
            </Link>
          </div>
        </li>
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            data-toggle="dropdown"
            href="#"
            role="button"
          >
            Être consultant Cleeven
          </a>
          <div className="dropdown-menu">
            <Link to="/consultant">
              <div className="dropdown-item text-uppercase text-grey">
                Métier de consultant
              </div>
            </Link>
            <Link to="/consultant">
              <div href="#" className="dropdown-item text-uppercase text-grey">
                Suivi de projet
              </div>
            </Link>
          </div>
        </li>
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            data-toggle="dropdown"
            href="#"
            role="button"
          >
            Ça bouge chez Cleeven
          </a>
          <div className="dropdown-menu">
            <Link to="/bouge">
              <div className="dropdown-item text-uppercase text-grey">
                Cleeven Coaching Program
              </div>
            </Link>
            <Link to="/bouge">
              <div className="dropdown-item text-uppercase text-grey">
                Boost Your Skills
              </div>
            </Link>
            <Link to="/bouge">
              <div className="dropdown-item text-uppercase text-grey">
                Événements conviviaux
              </div>
            </Link>
            <Link to="/bouge">
              <div className="dropdown-item text-uppercase text-grey">
                Cooptation
              </div>
            </Link>
          </div>
        </li>
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            data-toggle="dropdown"
            href="#"
            role="button"
          >
            Notre collaboration
          </a>
          <div className="dropdown-menu">
            <Link to="/collaboration">
              <div className="dropdown-item text-uppercase text-grey">
                Règles des demandes de congés
              </div>
            </Link>
            <Link to="/collaboration">
              <div className="dropdown-item text-uppercase text-grey">
                Gestion des temps
              </div>
            </Link>
            <Link to="/collaboration">
              <div className="dropdown-item text-uppercase text-grey">
                Justification des absences
              </div>
            </Link>
          </div>
        </li>
      </ul>
    </nav>
  </nav>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
