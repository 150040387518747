import React from "react"

import atomesLeft from "../images/atomes-left.png";
import atomes from "../images/atomes.png";

const SectionTitle = ({text, iconImg, left}) => (
  <div style={{backgroundImage: `url(${left ? atomesLeft: atomes})`, backgroundPosition: `center ${left ? 'left' : 'right'}`}} id="section-title" className="col-md-12">
      <div className="col-md-12 icon text-center mb-4">
            <img src={iconImg} alt={text} />
      </div>
      <div className="bar" />
      <div className="col-md-12 text text-center">
        {text}
      </div>
      <div className="bar"/>
  </div>
)

export default SectionTitle;