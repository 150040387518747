import React from "react"

import arrowIcon from "../images/icon-arrow.png";

const ScrollUp = () => (
  <button type="button" className="btn btn-light" id="scroll-up" onClick={() => {
    window.scrollTo({top: 0, behavior: "smooth"});
  }}>
    <img src={arrowIcon} alt="Aller en haut"/>
  </button>
);

export default ScrollUp;