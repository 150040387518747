import React from "react"

const Footer = () => (
  <div className="text-center m-5 text-lightGray" id="footer">
        <span className="text pt-3 pb-3">
            ACCÉLÉRATEUR D’EXCELLENCE
        </span>
  </div>
);

export default Footer;
