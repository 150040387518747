import React from "react"

const SectionHeader = ({whiteText, inversed, title}) => (
  <div className="col-md-12 mb-4">
      <span className={`${whiteText ? 'text-white' : 'text-blue'} ml-5 title pl-3 pr-3 pt-1 pb-1 section-header`}>
        {title}
      </span>
  </div>
)

export default SectionHeader