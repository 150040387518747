import React from "react"

const Hero = ({text, id}) => (
  <div className="col-md-12">
    <div className="d-flex flex-column align-items-center justify-content-center" id={`${id}-hero`}>
      <span className="p-5 font-weight-bold text-white">{text}</span>
    </div>
  </div>
)

export default Hero;